import React from "react"

import { useAnalytics } from "../../../hooks/useAnalytics"

export const withFeaturedArticles = Component => ({
  name = "FeaturedArticles",
  analytics = {},
  title,
  articles,
}) => {
  const { trackPromoImpression, VisibilitySensor } = useAnalytics()

  Component.displayName = name
  return (
    <VisibilitySensor
      onChange={visible => visible && trackPromoImpression({ ...analytics })}
    >
      <Component title={title} articles={articles} />
    </VisibilitySensor>
  )
}
